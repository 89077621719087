var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row mt-3"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col d-flex"},[_c('h3',{staticClass:"my-auto",domProps:{"textContent":_vm._s(_vm.title)}})]),_c('div',{staticClass:"col-auto"},[_c('v-btn',{staticClass:"text--primary text-none elevation-0 px-5",attrs:{"color":"secondary","dark":""},on:{"click":function($event){return _vm.openActivityModal()}}},[_vm._v(" Nuevo ")])],1)])]),_c('div',{staticClass:"col-12"},[_c('data-table',{key:_vm.url,ref:"secondSectionTable",attrs:{"url":_vm.url,"params":_vm.params,"headers":_vm.headersSecondSection,"mobile":true,"withoutPagination":true},scopedSlots:_vm._u([{key:"item.arrows",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.last)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"large":"","color":"black","center":""},on:{"click":function($event){return _vm.onChangeOrderActivity(item, 0)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-angle-down ")]):_c('v-icon',{staticClass:"ml-10 pl-1",attrs:{"large":""}})]}}],null,true)},[_c('span',[_vm._v("Subir Posición")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(!item.first)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-5",attrs:{"large":"","color":"black","center":""},on:{"click":function($event){return _vm.onChangeOrderActivity(item, 1)}}},'v-icon',attrs,false),on),[_vm._v(" fa fa-angle-up ")]):_c('v-icon',{staticClass:"ml-5",attrs:{"large":""}})]}}],null,true)},[_c('span',[_vm._v("Bajar Posición")])])]}},{key:"item.isActive",fn:function(ref){
var item = ref.item;
return [_c('v-switch',{staticClass:"ma-0",attrs:{"dense":"","hide-details":""},on:{"click":function($event){return _vm.onChangeStatusActivity(item)}},model:{value:(item.isActive),callback:function ($$v) {_vm.$set(item, "isActive", $$v)},expression:"item.isActive"}})]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.openActivityModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-edit ")])],1)]}}],null,true)},[_c('span',[_vm._v("Editar Actividad")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"ma-2 elevation-0",attrs:{"outlined":"","small":"","color":"primary"},on:{"click":function($event){return _vm.onDeleteActivity(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"center":"","dark":"","small":""}},[_vm._v(" fa fa-trash ")])],1)]}}],null,true)},[_c('span',[_vm._v("Eliminar Actividad")])])]}}])}),_c('second-section-modal',{ref:"secondSectionModal",attrs:{"fairId":_vm.fair.id,"url":_vm.url},on:{"onClose":_vm.onClose}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":("¿Deseas " + (Boolean(_vm.selectedActivity.isActive) ? 'activar' : 'desactivar') + " la actividad?"),"is-active":_vm.isConfirmChangeStatusActivity},on:{"onClose":function($event){return _vm.closeChangeStatusActivityModal()},"onConfirm":function($event){return _vm.changeStatusActivity()}}}),_c('confirm-dialog',{attrs:{"title":'Confirmación',"description":("¿Deseas eliminar la actividad " + (_vm.selectedActivity.name) + "?"),"is-active":_vm.isConfirmDeleteActivity},on:{"onClose":function($event){return _vm.closeDeleteActivityModal()},"onConfirm":function($event){return _vm.deleteActivity()}}})],1)])}
var staticRenderFns = []

export { render, staticRenderFns }